import React from "react";
import {NavLink, useLocation} from 'react-router-dom';
import classes from './NavigationItem.module.css';
import Container from '../../../../hoc/Container/Container';

const NavigationItem = (props) => {
    
    let subMenu;
    if(props.submenu != null) {
            if(props.submenu.subMenuItems != null && props.activeSubMenuId === props.id && !props.showMobileMenu){
            subMenu= (
                <div className={classes.DropDownContainer}>
                {props.submenu.subMenuItems.map( subMenuItem => (
                    <NavLink key={subMenuItem.title} to={subMenuItem.link} exact>
                        <div onClick={props.handleLeave}>
                            {subMenuItem.title} 
                        </div>
                    </NavLink> 
                ))}
                </div> 
            );
        }
    }

    const location = useLocation();  
    let navItem;
    //NavItem with submenu on desktop
    if(props.submenu != null) {
        if(props.submenu.subMenuItems != null){
            const parentPath = location.pathname.split('/')[1];
            const activeSet = props.link === "/" + parentPath;
            console.log(props.id)
            navItem = (
                <li className={classes.NavigationItem} onMouseEnter={() => props.handleHover(props.id)} onMouseLeave={() => props.handleLeave(props.id)}>
                    <NavLink activeClassName={activeSet ? classes.active : null} onClick={() => props.handleHover(props.id)} to={"#"} exact> {props.children} </NavLink>
                    {subMenu}
                </li>
            )
        } 
    }

    //NavItem without submenu on desktop
    if(props.submenu != null) {
        if(props.submenu.subMenuItems === null && !props.showMobileMenu) {
            navItem = (
            <li className={classes.NavigationItem}>
                <NavLink  activeClassName={classes.active} to={props.link} exact> {props.children} </NavLink>
            </li>
            );
        }
    }

    //NavItem with submenu on mobile
    if(props.submenu != null) {
        if(props.submenu.subMenuItems != null && props.showMobileMenu){
            navItem = (
                <li className={classes.NavigationItem} >
                    <NavLink onClick={ () => props.mobileSubMenuHandler(props.submenu.subMenuItems)} to={"#"} exact> {props.children} </NavLink>
                </li>
            )
        } 
    }
    //NavItem without submenu on mobile
    if(props.submenu != null) {
        if(props.submenu.subMenuItems === null && props.showMobileMenu) {
            navItem = (
            <li className={classes.NavigationItem}>
                <NavLink  onClick={props.drawerToggleClicked} activeClassName={classes.active} to={props.link} exact> {props.children} </NavLink>
            </li>
            );
        }
    }

    //submenu Nav item on mobile
    if(props.isSubmenu && props.showMobileMenu) {
        navItem = (
            <li className={classes.NavigationItem} >
                    <NavLink onClick={props.drawerToggleClicked} activeClassName={classes.active} to={props.link} exact> {props.children} </NavLink>
            </li>
        );
    }
    return (
        <Container> 
            {navItem}
        </Container>
    );
}
    
export default NavigationItem