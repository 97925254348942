import React, { useEffect, useRef, useState } from 'react';
import classes from './HomeInfo.module.css';
import Image from '../../Image/Image';
import {NavLink} from 'react-router-dom';

const HomeInfo = (props) => {
    const [showInfo, setShowInfo] = useState(false); 
    const [divPostion, setDivPostion] = useState(0);

    const constSetHeightHandler = () => {
        const divPositionOnLoad =  homeInfoRef.current.getBoundingClientRect().top;
        setDivPostion(divPositionOnLoad);
    }

    const homeInfoRef = useRef(null);
    
    useEffect(() => {
        const handleScroll = () => {
            const divTopPostion = divPostion;  
            const scrollPos = window.scrollY + window.innerHeight;   
            if (divTopPostion < scrollPos) {
                    setShowInfo(true);
            } 
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [divPostion]);

    let startingSide = props.leftEntrance ? classes.LeftStart: classes.RightStart;
    let homeInfoClasses = [startingSide, classes.InfoContainer];
    if(showInfo)  {
        homeInfoClasses = [startingSide, classes.InfoContainer, classes.Animate];
    }
  
    return(
        <div className={homeInfoClasses.join(" ")} ref={homeInfoRef}>
            <NavLink activeClassName={classes.active} to={props.link} exact>  {props.children} 
                <h2>{props.title}</h2>
                <Image setHeight={constSetHeightHandler} src={props.imageSrc} alt={props.imageAlt}> </Image>
                <p>{props.text}</p>
            </NavLink>
        </div>
    )
}
        


export default HomeInfo; 
