import React from 'react';
import classes from './FooterAddress.module.css';

const footerAddress = (props) => (
    <div className={classes.OfficeInfo}> 
        <h2>{props.title} </h2>
        <h3>{props.companyName} </h3>
        <p>{props.addressLine1}</p> 
        <p>{props.addressLine2}</p> 
        <p>{props.county} {props.postCode} </p> 
        <h3>Opening Times</h3> 
        <p>{props.openingHoursWeekDay}</p>
        {props.openingHoursFriday ? <p>{props.openingHoursFriday}</p> : ""} 
        <p>{props.openingHoursWeekEnd}</p> 
        <p>{props.notes}</p> 
    </div> 
);

export default footerAddress; 