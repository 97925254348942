import React from 'react';
import classes from './ContactDetails.module.css';

const contact = (props) => (
    <div className={classes.ContactDetails}>
        <div className={classes.ContactInfo}>
            <h3>Call:</h3>
            <a href="tel:01424 754247" > <p>01424 754247</p> </a>
        </div>
        <div className={classes.ContactInfo}>
            <h3>Email:</h3>
            <a href="mailto: info@sjfeistandco.co.uk" > <p>info@sjfeistandco.co.uk</p> </a>
        </div>
        <div className={classes.Location}>
            <p>Address:</p>
            <p>Chameleon House</p>
            <p>Drury Lane</p>
            <p>East Sussex TN38 9BA</p>
            <br></br>
            <p>Opening hours:</p>
            <p>8:30 am - 5.00 PM Monday to Friday</p>
            <p>9:00 am - 2:00 PM Saturdays</p>
            <p>Closed Sundays</p>
        </div>
        




    </div>
);

export default contact; 
