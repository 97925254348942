import React from 'react';
// import { NavLink } from 'react-router-dom';
import classes from './Advert.module.css'

const advert = (props) => (
    <div className={classes.AdvertContainer}>
        <p onClick={props.closePopUp} className={classes.Cross}>X</p>
        <div className={classes.AdvertTextContainer}>
            <p>{props.popUpText}</p>
        </div>    
    </div>
)
export default advert;
