import React from "react";
import classes from "./PopUpMessage.module.css";
import Modal from "../Modal/Modal";
import Advert from "../Advert/Advert";

const popUpMessage = (props) => {
    let popUpClass = classes.Hide;
    if(props.showMessage){
        popUpClass = classes.Show
    }
    return(
        <div className={popUpClass}>
            <Modal>
                <Advert closePopUp={props.closePopUp} popUpText={props.popUpText}/>
            </Modal>
        </div>
    )
}
   
export default popUpMessage;