import React from 'react';
import classes from './MenuIcon.module.css';

const menuIcon = (props) => {
    let topAttachedClasses = [classes.MenuTopClosed, classes.TransitionDelayClosed];
    let bottomAttachedClasses = [classes.MenuBottomClosed, classes.TransitionDelayClosed];
    
        if(props.isHomePage){
            topAttachedClasses = [classes.MenuTopClosedHome, classes.TransitionDelayClosed];
            bottomAttachedClasses = [classes.MenuBottomClosedHome, classes.TransitionDelayClosed];
        }

    if (props.showMobileMenu) {
        topAttachedClasses = [classes.TopShow, classes.TransitionDelayOpen];
        bottomAttachedClasses = [classes.BottomShow, classes.TransitionDelayOpen];
    } 
    
    


    return(
    <div className={classes.MenuIcon} onClick={props.clicked}>
            <div className={topAttachedClasses.join(' ')}></div>
            <div className={bottomAttachedClasses.join(' ')}></div>
    </div>
   );
}

export default menuIcon;


