import React, { useState,  useEffect} from "react";
import Container from '../Container/Container';
import Toolbar from '../../components/Toolbar/Toolbar';
import MobileMenu from '../../components/Navigation/MobileMenu/MobileMenu';
import classes from './Layout.module.css';
import PopUpMessage from '../../components/PopUpMessage/PopUpMessage';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router';


const Layout = (props) => {
    const [menuIsVisible, setMenuIsVisable] = useState(false);
    const [isHomePage, setIsHomePage] = useState(true);
    const [showSubMenu] = useState(false);
    const [activeSubMenuId, setActiveSubMenuId] = useState(0);
    const [mobileSubMenuIsVisable, setMobileSubMenuIsVisable] = useState(false);
    const [subMenuList, setSubMenuList] = useState(null);
    const [showMessage, setShowMessage] = useState(true);
    const [popUpEnabled, setPopUpEnabled] = useState(false);
    const [popUpText, setPopUpText] = useState("");
    
    useEffect(() => {
        fetch('https://sjfeistdemosite.firebaseio.com/PopUp.json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
              }
        }).then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              return res.json().then((data) => {
                let errorMessage = 'Authentication Failed';
                throw new Error(errorMessage);
              });
            }
          })
          .then((data) => {
            setPopUpEnabled(data.Enabled);
            setPopUpText(data.text);
          })
          .catch((err) => {
            console.log(err.message);
          });
     
    }, [])
    const MobileMenuClosedHandler = () => {
        setMenuIsVisable(false);
        setMobileSubMenuIsVisable(false);
    }
    const MobileMenuToggleHandler = () => {
       setMenuIsVisable(!menuIsVisible);
       setMobileSubMenuIsVisable(false);
    }

    const MobileSubMenuHandler = (list) => {
        setMobileSubMenuIsVisable(!mobileSubMenuIsVisable);
        setSubMenuList(list)
    }

    const handleHover = (id) => {
        setActiveSubMenuId(id);
    };
      
    const handleLeave = () => {
        setMobileSubMenuIsVisable(false);
        setActiveSubMenuId(0);
    };

    const closeMessage = () => {
        setShowMessage(false);
    };

    const location = useLocation();
    useEffect(() => {
        if(location.pathname === "/"){
            setIsHomePage(true);
        } else{
            setIsHomePage(false);
            setShowMessage(false);
        }
    },[location.pathname]);

    let popUp; 
    if (popUpEnabled) {
       popUp = (<PopUpMessage showMessage={showMessage} closePopUp={closeMessage} popUpText={popUpText}/>)
    }
    
    return(
        <Container>
            {popUp}
            <Toolbar isHomePage={isHomePage} drawerToggleClicked={MobileMenuToggleHandler} showMobileMenu={menuIsVisible} handleHover={handleHover} handleLeave={handleLeave} showSubMenu={showSubMenu} activeSubMenuId={activeSubMenuId}/>
            <MobileMenu open={menuIsVisible} closed={MobileMenuClosedHandler} drawerToggleClicked={MobileMenuToggleHandler} showMobileMenu={menuIsVisible} mobileSubMenuHandler={MobileSubMenuHandler} showSubMenu={mobileSubMenuIsVisable} subMenuList={subMenuList}/>
            <main className={classes.Content}>
                {props.children}
                <Footer/>
            </main>
            </Container>
    );
}

export default Layout;