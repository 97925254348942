import React, { useState, useEffect } from 'react';
import classes from './Contact.module.css';
import ContactDetails from './ContactDetails/contactDetails';
import axios from 'axios';
import Spinner from '../../components/UI/Spinner/Spinner';
import Tick from '../../assets/tick.png';


const Contact = (props) => {
  const [firstName, setFirstName] = useState({value:"", validation:{required:true, maxLenth: 25 , errorMessage:""}, valid: false, touched: false, shouldValidate: true});
  const [surname, setSurname] = useState({value:"", validation:{required:true, maxLenth: 25, errorMessage:""}, valid: false, touched: false, shouldValidate: true});
  const [email, setEmail] = useState({value:"", validation:{required:true, errorMessage:""}, valid: false, touched: false, shouldValidate: true});
  const [contactNumber, setContactNumber] = useState({value:"", validation:{required:true, errorMessage:""}, valid: false, touched: false, shouldValidate: true});
  const [message, setMessage] = useState({value:"", validation:{required:true, minLength:10, errorMessage:""}, valid: false, touched: false, shouldValidate: true});
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [formIsVaild, setFormIsValid] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkValidity = (value, rules) =>  {
    let isValid = true;
    let errorMessage =""
    if(rules.required) {
        if(value.trim() === '' && isValid) {
          errorMessage = "is required";
          isValid = false;
        }
    }
    if(rules.minLength) {
        if(value.length <= rules.minLength && isValid) {
          errorMessage = "must be larger than " + rules.minLength;
          isValid = false;
        }       
    }
    
    if(rules.maxLenth) {
        if(value.length >= rules.maxLenth && isValid) { 
          errorMessage = "must be smalller than " + rules.maxLenth;
          isValid = false;
        }
    }
    return errorMessage;
}

  const inputChangeHandler = (event, input, inputUpdater) => {
    const validationArray = {...input.validation};
    const inputShouldValidate = input.shouldValidate; 
    const errorMessage = checkValidity(event.target.value, validationArray); 
    validationArray.errorMessage = errorMessage;
    const valid = !errorMessage;  
    inputUpdater({value:event.target.value, validation: validationArray, valid: valid, touched: true, shouldValidate: inputShouldValidate}); 
    const currentFormData = [firstName, surname,  email, contactNumber, message];
    let formIsValidVar = true;
    for( let inputs in currentFormData){
      formIsValidVar = currentFormData[inputs].valid && formIsValidVar;
    }
    setFormIsValid(formIsValidVar);
  }
  


  const sendEmail = (event) => {
    event.preventDefault();
    setLoading(true);
      const data = {
        service_id: 'default_service',
        template_id: 'template_6ppJ9fqo',
        user_id: 'user_NWDyzddo0gPiZ4QtNFIgp',
        template_params: {
          'firstName': firstName.value,
          'surname': surname.value,
          'email': email.value,
          'contactNumber': contactNumber.value,
          'message': message.value
        }
      }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
    .then(response => {
      setEmailSent(true);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  }

  let form = (
    <form onSubmit={sendEmail}>
    <input className={!firstName.valid && firstName.shouldValidate && firstName.touched ? classes.InputError : null} type="text" placeholder="First Name" name="first Name" value={firstName.value} onChange={event => inputChangeHandler(event, firstName, setFirstName)}/>
    {firstName.validation.errorMessage ? <p className={classes.ErrorMessage}> First Name {firstName.validation.errorMessage}</p> : null }
    <input className={!surname.valid && surname.shouldValidate && surname.touched ? classes.InputError : null} type="text" placeholder="Surname" name="surname"  value={surname.value} onChange={event => inputChangeHandler(event, surname, setSurname)}/>
    {surname.validation.errorMessage ? <p className={classes.ErrorMessage}> Surname {surname.validation.errorMessage}</p> : null }
    <input className={!email.valid && email.shouldValidate && email.touched ? classes.InputError : null} type="email" placeholder="Email" name="email" value={email.value} onChange={event => inputChangeHandler(event, email, setEmail)}/>
    {email.validation.errorMessage ? <p className={classes.ErrorMessage}> Email {email.validation.errorMessage}</p> : null }
    <input className={!contactNumber.valid && contactNumber.shouldValidate && contactNumber.touched ? classes.InputError : null} type="tel" placeholder="Contact Number" name="contact number" value={contactNumber.value} onChange={event => inputChangeHandler(event, contactNumber, setContactNumber)}/>
    {contactNumber.validation.errorMessage ? <p className={classes.ErrorMessage}> Contact Number {contactNumber.validation.errorMessage}</p> : null }
    <textarea className={!message.valid && message.shouldValidate && message.touched ? classes.InputError : null} placeholder="Type your message here" name="message" value={message.value} onChange={event => inputChangeHandler(event, message, setMessage)}></textarea>
    {message.validation.errorMessage ? <p className={classes.ErrorMessage}> Message {message.validation.errorMessage}</p> : null }
    <button disabled={!formIsVaild}> Send </button>
    </form>
  );
 
  const confirmationMessage = (
    <div className={classes.ConfirmationMessage}>
      <p> Thanks for your message. </p>
      <p> We will get back as soon as we can! </p> 
      <img src={Tick} alt={"email sent confrimation"} />
    </div>
  );
    
  if(loading) {
    form = <Spinner />
  }

  if(emailSent) {
    form = confirmationMessage;
  }
  
    return (
    <div className={classes.Contact}>
      <div className={classes.ContactContainer}>
        <ContactDetails/>
        <div className={classes.FormContainer}>
          <h2>CONTACT FORM</h2>
          {form}
        </div>
      </div>
    </div>
  );
}

export default Contact; 

