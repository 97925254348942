import React from 'react';
import classes from './MobileMenu.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';
const mobileMenu =(props) => {
      
    let attachedClasses = [classes.MobileMenu, classes.Close];
    document.body.style.overflow = "unset";
    if (props.open) {
        attachedClasses = [classes.MobileMenu, classes.Open];
        document.body.style.overflow = "hidden";
    }

    return(
            <div className={attachedClasses.join(" ")}>
                <nav> 
                <NavigationItems  drawerToggleClicked={props.drawerToggleClicked} showMobileMenu={props.showMobileMenu} mobileSubMenuHandler={props.mobileSubMenuHandler} showSubMenu={props.showSubMenu}  subMenuList={props.subMenuList}/>
                </nav>
            </div>
    );
    
}

export default mobileMenu;