import React, { Suspense, useContext } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/home';
import Contact from './containers/Contact/Contact';
import Spinner from './components/UI/Spinner/Spinner';
import AuthContext from './containers/Backend/Store/auth-context';

const Servicing = React.lazy(() => {
  return import('./containers/Servicing/Servcing');
});

const Installations = React.lazy(() => {
  return import('./containers/Installations/Installations');
});

const Renewables = React.lazy(() => {
  return import('./containers/Renewables/Renewables');
});

const Gallery = React.lazy(() => {
  return import('./containers/Gallery/Gallery');
});

const Testimonals = React.lazy(() => {
  return import('./containers/Testimonials/Testimonials');
});

const Careers = React.lazy(() => {
  return import('./containers/careers/Careers');
});

const Bathrooms = React.lazy(() => {
  return import('./containers/Products/Bathrooms/Bathrooms');
});

const Boilers = React.lazy(() => {
  return import('./containers/Products/Boilers/Boilers');
});

const Kitchens = React.lazy(() => {
  return import('./containers/Products/Kitchens/Kitchens');
});

const BespokeKitchens = React.lazy(() => {
  return import('./containers/Products/BespokeKitchens/BespokeKitchens');
});

const RangeCookers = React.lazy(() => {
  return import('./containers/Products/RangeCookers/RangeCookers');
});

const Everhot = React.lazy(() => {
  return import('./containers/Products/Everhot/Everhot');
});

const Aga = React.lazy(() => {
  return import('./containers/Products/Aga/Aga');
});

const WoodBurners = React.lazy(() => {
  return import('./containers/Products/WoodBurners/WoodBurners');
});

const OilTanks = React.lazy(() => {
  return import('./containers/Products/OilTanks/OilTanks');
});

const PelletStoves = React.lazy(() => {
  return import('./containers/Products/PelletStoves/PelletStoves');
});

const Cylinders = React.lazy(() => {
  return import('./containers/Products/Cylinders/Cylinders');
});

const SmartControls = React.lazy(() => {
  return import('./containers/Products/SmartControls/SmartControls');
});

const PrivacyPolicy = React.lazy(() => {
  return import('./containers/PrivacyPolicy/PrivacyPolicy');
});

const TermsOfUse = React.lazy(() => {
  return import('./containers/TermsOfUse/TermsOfUse');
});

const CovidPolicy = React.lazy(() => {
  return import('./containers/CovidPolicy/CovidPolicy');
});

const AdminHome = React.lazy(() => {
  return import('./containers/Backend/AdminHome/AdminHome');
});

const Login = React.lazy(() => {
  return import('./containers/Backend/Login/Login');
});

const Logout = React.lazy(() => {
  return import('./containers/Backend/Logout/Logout');
});

const PopUp = React.lazy(() => {
  return import('./containers/Backend/PopUp/PopUp');
});

const Vacancies = React.lazy(() => {
  return import('./containers/Backend/Vacancies/Vacancies');
});

const AddVanancy = React.lazy(() => {
  return import('./containers/Backend/AddVacancy/AddVacancy');
});

const EditVanancy = React.lazy(() => {
  return import('./containers/Backend/EditVacancy/EditVacancy');
});


function App() {
  const authCtx = useContext(AuthContext);
  const routes = (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/contactUs" exact component={Contact} />
      <Route path="/servicing" render={() => <Servicing />} />
      <Route path="/installations" render={() => <Installations />} />
      <Route path="/renewables" render={() => <Renewables />} />
      <Route path="/gallery" render={() => <Gallery />} />
      <Route path="/aboutUs/testimonials" render={() => <Testimonals />} />
      <Route path="/aboutUs/careers" render={() => <Careers />} />
      <Route path="/products/bathrooms" render={() => <Bathrooms />} />
      <Route path="/products/kitchens" render={() => <Kitchens />} />
      <Route path="/products/BespokeKitchens" render={() => <BespokeKitchens />} />
      <Route path="/products/rangeCookers" render={() => <RangeCookers />} />
      <Route path="/products/everhot" render={() => <Everhot />} />
      <Route path="/products/aga" render={() => <Aga />} />
      <Route path="/products/boilers" render={() => <Boilers />} />
      <Route path="/products/woodBurners" render={() => <WoodBurners />} />
      <Route path="/products/oilTanks" render={() => <OilTanks />} />
      <Route path="/products/pelletStoves" render={() => <PelletStoves />} />
      <Route path="/products/cylinders" render={() => <Cylinders />} />
      <Route path="/products/smartContols" render={() => <SmartControls />} />
      <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
      <Route path="/terms-of-use" render={() => <TermsOfUse />} />
      <Route path="/covid-policy" render={() => <CovidPolicy />} />
      <Route path="/admin/logout" render={() => <Logout/>} />
      <Route path="/admin/login"> 
        {!authCtx.isLoggedIn && <Login/>}
        {authCtx.isLoggedIn && <Redirect to='/admin'/>} 
      </Route>
      <Route path="/admin/edit-popup">
        {authCtx.isLoggedIn && <PopUp />}
        {!authCtx.isLoggedIn && <Redirect to='/admin/login' />}
      </Route>
      <Route path="/admin/edit-vacancies">
        {authCtx.isLoggedIn && <Vacancies />}
        {!authCtx.isLoggedIn && <Redirect to='/admin/login' />}
      </Route>
      <Route path="/admin/add-new-vacancy">
        {authCtx.isLoggedIn && <AddVanancy />}
        {!authCtx.isLoggedIn && <Redirect to='/admin/login' />}
      </Route>
      <Route path="/admin/edit-vacancy">
        {authCtx.isLoggedIn && <EditVanancy />}
        {!authCtx.isLoggedIn && <Redirect to='/admin/login' />}
      </Route>
      <Route path="/admin">
        {authCtx.isLoggedIn && <AdminHome />}
        {!authCtx.isLoggedIn && <Redirect to='/admin/login' />}
      </Route>
    </Switch>
  );

  return (
    <div>
      <Layout>
        <Suspense fallback={<div style={{minHeight:"70vh"}}> <Spinner/> </div>}>{routes}</Suspense>
      </Layout>
    </div>
  );
}

export default App;
