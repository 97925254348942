import React from 'react';
import classes from './RegisteredAdress.module.css';
import FooterImage from '../FooterImage/FooterImage';
import gasSafeImg from '../../../assets/gasSafe.jpg';
import oftecImg from '../../../assets/OFTEC.png';
import hetasImg from '../../../assets/hetas-logo2.jpg';


const images = [
    {   title: 'Gas Safe',
        source: gasSafeImg,
        alt: "Gas safe"
    },
    {   title: 'oftec',
        source: oftecImg,
        alt: "OFTEC"
    },
    {   title: 'hetas',
        source: hetasImg,
        alt: "Hetas"
    },
]

const registeredAddress = (props) => (
    <div className={classes.RegistredInfo}>
        <div className={classes.FooterImages}>
        {images.map(image => (
                    <FooterImage key={image.title} title = {image.title} footerImage = {image.source} imageAlt = {image.alt} />
                ))}                      
        </div>
        <h2>Registered address: </h2>   
        <p>Chameleon House,</p>
        <p>Drury Lane, TN38 9BA. </p>
        <p>Company number: 4773281</p>
        <p>Registered in England.</p>
        <p>VAT No: 583873492.</p>    
    </div>  
);

export default registeredAddress; 