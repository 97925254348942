import React, { useEffect, useState } from 'react';
import classes from './home.module.css';
import Container from '../../hoc/Container/Container';
import HomeInfos from '../../components/HomeInfos/HomeInfos';
import background from '../../assets/Background.jpg';
import agaBackgroundImage from '../../assets/Aga5.jpg';
import everhotGreen from '../../assets/RangeCookerCoverImg.jpg';

const Home = (props) => {
    const [currentImage, setCurrentImage] = useState(0);

    const imageList = [
        {
            image: agaBackgroundImage,
            alt: "Aga cooker",
            key: 1
        },
        {
            image: everhotGreen,
            alt: "everhot cooker",
            key: 2
        },
        {
            image: background,
            alt: "SJ Feist background",
            key: 3 
        } 
    ]
    const delayTime = 8000;

    useEffect(() => {
        const interval = setInterval(() => {
            if(currentImage < 2) {
                setCurrentImage(currentImage + 1)
                console.log(currentImage)
            }
            else{
                setCurrentImage(0);
            }
      }, delayTime);
    
      return () => clearInterval(interval); 
    }, [currentImage])

    return (
        <Container>
        <div className={classes.MastHead}>
            <img src={imageList[currentImage].image} alt={imageList[currentImage].alt} key={imageList[currentImage].key} />
        </div>

        <HomeInfos />
    </Container>
    )
}
   
     


export default Home; 
