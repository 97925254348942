import React from 'react';
import classes from './Toolbar.module.css';
import MenuIcon from '../Navigation/MenuIcon/MenuIcon';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import LogoToolBar from '../LogoToolBar/LogoToolBar';
import { NavLink } from 'react-router-dom';
import Container from '../../hoc/Container/Container';

const toolbar = (props) => (
    <Container>
      <header className={classes.Toolbar}>
          <div className={classes.HeaderContainer}>
              <MenuIcon clicked={props.drawerToggleClicked} showMobileMenu={props.showMobileMenu} isHomePage={props.isHomePage} />
              <NavLink activeClassName={classes.active} to="/" exact> <LogoToolBar hide={props.isHomePage}/> </NavLink>
              <nav className={classes.DesktopOnly}>
                <NavigationItems handleHover={props.handleHover} handleLeave={props.handleLeave} showSubMenu={props.showSubMenu} activeSubMenuId={props.activeSubMenuId}/>
              </nav>
          </div>
      </header>
  </Container>
)
   
export default toolbar