import React from 'react';
import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Container from '../../../hoc/Container/Container';
import { useContext } from 'react';
import AuthContext from '../../../containers/Backend/Store/auth-context';

const NavigationItems = (props) => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const navigationItems = [
        {
            "id": 1,
            "title": "INSTALLATIONS",
            "link": "/installations",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {   
            "id": 2,
            "title": "SERVICING & REPAIRS",
            "link": "/Servicing",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {
            "id": 3,
            "title": "RENEWABLES",
            "link": "/renewables",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {
            "id": 4,
            "title": "PRODUCTS",
            "link": "/products",
            "subMenu": {
                "parent": "/products",
                "subMenuItems": [
                    {
                        "title": "BOILERS",
                        "link": "/products/boilers"    
                    },
                    {
                        "title": "BATHROOMS",
                        "link": "/products/bathrooms"
                    },
                    {
                        "title": "KITCHENS",
                        "link": "/products/kitchens"
                    },
                    {
                        "title": "BESPOKE KITCHENS",
                        "link": "/products/bespokeKitchens"
                    },
                    {
                        "title": "RANGE COOKERS",
                        "link": "/products/rangeCookers"
                    },
                    {
                        "title": "EVERHOT",
                        "link": "/products/everhot"
                    },
                    {
                        "title": "AGA",
                        "link": "/products/aga"
                    },
                    {
                        "title": "WOOD BURNERS",
                        "link": "/products/woodBurners"
                    },
                    {
                        "title": "OIL TANKS",
                        "link": "/products/oilTanks" 
                    },
                    {
                        "title": "PELLET STOVES",
                        "link": "/products/pelletStoves" 
                    },
                    {
                        "title": "CYLINDERS",
                        "link": "/products/cylinders" 
                    },
                    {
                        "title": "SMART CONTOLS",
                        "link": "/products/smartContols" 
                    }
                ]
            }
        },
        // {
        //     "id": 5,
        //     "title": "Gallery",
        //     "link": "/gallery",
        //     "subMenu": {
        //         "parent": null,
        //         "subMenuItems": null
        //     }
        // },
        {
            "id": 6,
            "title": "ABOUT US",
            "link": "/aboutUs",
            "subMenu": {
                "parent": "aboutUs",
                "subMenuItems": [
                    {
                        "title": "TESTIMONIALS",
                        "link": "/aboutUs/testimonials"    
                    },
                    {
                        "title": "CAREER OPPORTUNITIES",
                        "link": "/aboutUs/careers"    
                    },

                ]
            }
        },
        {
            "id": 7,
            "title": "CONTACT US",
            "link": "/contactUs",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
    ]

    const adminNavigationItems = [
        {
            "id": 1,
            "title": "ADMIN HOME",
            "link": "/admin/",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {
            "id": 2,
            "title": "POP-UP",
            "link": "/admin/edit-popup",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {
            "id": 3,
            "title": "VACANCIES",
            "link": "/admin/edit-vacancies",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        },
        {
            "id": 4,
            "title": "LOGOUT",
            "link": "/admin/logout",
            "subMenu": {
                "parent": null,
                "subMenuItems": null
            }
        }
    ]
    let mainNavigation = (
        <Container>
             {navigationItems.map(item => (
                <NavigationItem key={item.title} id={item.id} link={item.link} submenu={item.subMenu} handleHover={props.handleHover} handleLeave={props.handleLeave} showSubMenu={props.showSubMenu} activeSubMenuId={props.activeSubMenuId}> {item.title}  </NavigationItem>
            ))}
        </Container>
    );

    let adminNavigation = null;
    if (isLoggedIn) {
        adminNavigation = (
            <Container>
                {adminNavigationItems.map(item => (
                    <NavigationItem key={item.title} id={item.id} link={item.link} submenu={item.subMenu} handleHover={props.handleHover} handleLeave={props.handleLeave} showSubMenu={props.showSubMenu} activeSubMenuId={props.activeSubMenuId}> {item.title}  </NavigationItem>
                ))}
            </Container>
        );
        mainNavigation = null;
    }
   

    let navigation = (
        <Container>
            {mainNavigation}
            {adminNavigation}
        </Container>
    )

    if(props.showMobileMenu) {
        navigation = (
            <Container>
                {navigationItems.map(item => (
                    <NavigationItem key={item.title} link={item.link} submenu={item.subMenu} drawerToggleClicked={props.drawerToggleClicked} showMobileMenu={props.showMobileMenu} mobileSubMenuHandler={props.mobileSubMenuHandler} showSubMenu={props.showSubMenu} > {item.title}  </NavigationItem>
                ))}
            </Container>
        );
    }

    if(props.showSubMenu  && props.showMobileMenu){
        navigation = (
            <Container>
                {props.subMenuList.map(subMenu => (
                     <NavigationItem key={subMenu.title} link={subMenu.link}  submenu={null} drawerToggleClicked={props.drawerToggleClicked} showMobileMenu={props.showMobileMenu} isSubmenu={true}> {subMenu.title}  </NavigationItem>
                ))}
                <p className={classes.BackButton} onClick={props.mobileSubMenuHandler} >{"<"}</p>
            </Container>
        )
    }

    return(
        <ul className={classes.NavigationItems}>
           {navigation}
        </ul>
        );
}

export default NavigationItems