import React from 'react';
import classes from './LogoToolBar.module.css';
import logoImage from '../../assets/logoToolBar.png';

const logoToolbar = (props) => {
    let logoClasses = [classes.Logo];
    

    return(
        <div className={logoClasses.join(" ")} style={{height: props.height}}>
            <img src={logoImage} alt="SJFesit Logo"/>
        </div>
    );
}

export default logoToolbar;