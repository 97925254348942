import React from 'react';
import ReactDOM from 'react-dom';
import Analytics from 'react-router-ga';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import { AuthContextProvider } from './containers/Backend/Store/auth-context';


const app = (
    <AuthContextProvider>
        <BrowserRouter>
            <Analytics id="UA-179281326-1">
                <App />
            </Analytics>
        </BrowserRouter>
    </AuthContextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
