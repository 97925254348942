import React from 'react';
import classes from './HomeInfos.module.css';
import HomeInfo from './HomeInfo/HomeInfo';
import installationImg from '../../assets/vortex-external-2.jpeg';
import servicingImg from '../../assets/servicingImg.jpg';
import renewableImg from '../../assets/renewableCover.jpeg';
import everhotImg from '../../assets/everhot_90.jpeg'
import agaImg from '../../assets/AGA_ER3.jpeg'

const HomeInfos =(props) => { 

    const infoArray = [
        {
            title: 'INSTALLATIONS',
            imageSrc: installationImg,
            imageAlt: 'Installations Image',
            link: 'installations',
            text: 'SJ Feist are experts in Domestic and Light commercial Oil, Gas and LPG boiler installations. As well as boilers we cover bathrooms, heat storage cookers and Renewable energy.'
        },
        {
            title: 'SERVICING',
            imageSrc: servicingImg,
            imageAlt: 'Servicing Image',
            link: 'servicing',
            text: 'Our Gas and Oil engineers are all fully qualified and expertly trained. We cover the Sussex and Kent region, we service renewable energy, oil boilers, gas boilers, wood burning stoves, AGA rayburns and other heat storage cookers.'
        },
        {
            title: 'RENEWABLES',
            imageSrc: renewableImg,
            imageAlt: 'Renewables Image',
            link: 'renewables',
            text: 'At SJ Feist and Co we supply and fit and service a wide range of renewables across Sussex and Kent. Renewable energy is key to the sustainability of our planet. At SJ Feist we understand that the market is changing and demand for renewables is on the rise.'
        },
        {
            title: 'EVERHOT',
            imageSrc: everhotImg,
            imageAlt: 'Everhot 90i series',
            link: 'products/everhot',
            text: 'Behind the classic good looks you’ll find a modern, energy efficient heat storage range which is absolutely perfect for great cooking whilst gently warming your kitchen. Versatile and welcoming, you’ll wonder how you ever lived without one.'
        },
        {
            title: 'AGA',
            imageSrc: agaImg,
            imageAlt: 'AGA Oven',
            link: 'products/aga',
            text: "For almost a century, AGA has been bringing life to the kitchen. We understand the importance of home, family, friends and good food and we know that our homes have to work harder than ever before. It's why we've created an AGA cooker for every stage of life. From first homes to forever homes, there's an AGA for every cook and every kitchen."
        },

    ]
    let leftside = false;
    return(
            <div className={classes.HomeInfo}>
                {infoArray.map(info => { 
                    leftside = !leftside;
                    return(
                        <HomeInfo key={info.title} title = {info.title} imageSrc = {info.imageSrc} imageAlt = {info.imageAlt} text ={info.text} link = {info.link} leftEntrance={leftside}/>
                    );
                    })
                }
            </div>
    );
    
}

export default HomeInfos;