import React from 'react';
import classes from './Footer.module.css';
import Addresses from './FooterAddresses/FooterAddresses';
import TermsOfUse from './TermsOfUse/TermsOfUse';

const footer = (props) => (

        <div className={classes.Footer}>
            <div className={classes.FooterContent}>
                    <Addresses />
            </div>
            <TermsOfUse />
        </div>
    

   
);

export default footer; 
