import React from 'react'; 
import classes from './TermsOfUse.module.css';
import {NavLink} from 'react-router-dom';
import FaceBookImg from '../../../assets/f_logo_RGB-White_58.png'

const termsOfUse = () => (
    <div className={classes.Links}>
        <div className={classes.Link}>
            <NavLink to="/privacy-policy" exact> <p>Pricacy Policy </p></NavLink>  
        </div>
        <div className={classes.Link}>
            <NavLink to="/terms-of-use" exact> <p>Website Terms of Use</p></NavLink>  
        </div>
        <div className={classes.Link}>
            <NavLink to="/covid-policy" exact> <p>Covid Policy</p></NavLink>  
        </div>
        <div className={classes.Link}>
           <a href="https://www.facebook.com/SJFeistandCoLtd/" ><img src={FaceBookImg} alt="facebook logo" /></a>  
        </div>
    </div>
)

export default termsOfUse;