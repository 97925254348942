import React from 'react';
import classes from './FooterAddresses.module.css';
import FooterAddress from './FooterAddress/FooterAddress';
import RegisteredAddress from '../RegisteredAddress/RegisteredAddress';

const footerAddresses =(props) => {
    
    const Addresses = [
        {
            title: 'Showroom and Office',
            companyName: 'SJ Feist and Co Ltd.',
            addressLine1: 'Chameleon House',
            addressLine2: 'Drury Lane',
            city: 'St. Leonards on Sea',
            county: 'East Sussex',
            postCode: 'TN38 9BA',
            openingHoursWeekDay: '8:30 am - 5.00 PM Monday to Friday',
            openingHoursWeekEnd: '9:00 am - 2:00 PM Saturdays',
            notes: 'Closed Sundays'
           
        }
    ]

    return(
            <div className={classes.Addresses}>
                {Addresses.map(address => (
                    <FooterAddress 
                        key= {address.title} 
                        title = {address.title} 
                        companyName = {address.companyName} 
                        addressLine1 = {address.addressLine1} 
                        addressLine2 = {address.addressLine2}
                        city = {address.city}
                        county = {address.county}
                        postCode = {address.postCode}
                        openingHoursWeekDay = {address.openingHoursWeekDay}
                        openingHoursFriday = {address.openingHoursFriday}
                        openingHoursWeekEnd = {address.openingHoursWeekEnd}
                        notes = {address.notes}
                        />
                ))}
                <RegisteredAddress />   
            </div>
    );
    
}

export default footerAddresses;